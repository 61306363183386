
import '../../assets/iconfont.css';
import { getArticleListByCategoryId } from '@/api';
import {useRouter} from 'vue-router';
import { ref, onMounted,onActivated } from 'vue';
import { useStore } from 'vuex';
export default {
  setup(){
    const router = useRouter();
    const pageCount = ref();
    const page = ref(1);
    const pageSize = 5;
    const articleList = ref([]);
    const loadingBar = ref(null);
    const store = useStore();
    const isLoading = ref(true);
    onMounted(() => {
      loadingBar.value;
    });
    onActivated(() => {
      articleList.value = null;
      isLoading.value = true;
      //刷新数据
      getArticleListByCategoryId({"categoryId": router.currentRoute.value.params.id, "page": page.value, "pageSize": pageSize}).then((res: any) => {
        pageCount.value = res.data.pages;
        articleList.value = res.data.list;
          isLoading.value = false;
      });
    });



    const loadArticle = () => {
          loadingBar.value.style.opacity = "1";
          getArticleListByCategoryId({"categoryId": router.currentRoute.value.params.id, "page": page.value, "pageSize": pageSize}).then((res: any) => {
            articleList.value = res.data.list;
            loadingBar.value.style.opacity = "0";
            scrollTo(0,0);
      });
    }

    const readArticle = (url) =>{ 
      store.dispatch('closeSidebar');
      router.push({
        path: '/p/' + url, 
      });
    }
  

    return {
      loadArticle,
      articleList,
      pageCount,
      page,
      loadingBar,
      readArticle,
      isLoading,
    }
  }

}
